import NeuralDashboad from "../product/neural.component";
const Roboticarm = () => {
     const featureBoxArr = [
        { url: require('../../Assets/features/dricon1.png'), text: 'Complete On-Chip Intelligence' },
        { url: require('../../Assets/icon_acces.png'), text: 'Additional Accessories' },
        { url: require('../../Assets/feature3.png'), text: 'Authority Alerts' },
        { url: require('../../Assets/feature4.png'), text: 'Voice Interaction' },
        { url: require('../../Assets/feature5.png'), text: 'Daily Reports' },
        { url: require('../../Assets/feature6.png'), text: 'User Friendly Dashboard' },
        { url: require('../../Assets/features/dricon6.png'), text: 'Enhanced Security and Privacy' },
        { url: require('../../Assets/feature8.png'), text: 'Self Navigation System' },
        { url: require('../../Assets/feature9.png'), text: 'Cloud Platform' },

    ];
    return (
        <div>
            <div className="container-fluid accord">
               <div className="row">

                <div className="col-lg-7 col-md-push-7">
                    <h3 className="mt50">Robotic Arm</h3>
                    <p>Tailor-made and flexible solution that provides a helping hand with a wide range of functional applications</p>
                    <a href="/contact" className="btn action2">Enquire now</a>
                </div>

                <div className="col-lg-5 col-md-pull-5">
                    <img src={require("../../Assets/robotic-arm-bg.png")} />
                </div>
			</div>
			
			
            </div>
			
			<div className="container-fluid acco-img hide">
				<div className="col-lg-12">
				 <video width="100%" autoPlay muted loop playsInline>
                    <source src={require("../../Assets/Accord1.mp4")} type="video/mp4" />
                    Your browser does not support the video tag.
                 </video>
				</div>
			</div>
			
			 <div className="container-fluid features">
				<h4>Features</h4>
				<div className="row">
					{featureBoxArr.map((item, key) => {
						item.textStyle = Object.assign(item.textStyle ? item.textStyle : {}, { width: '60%', display: 'inline-block', verticalAlign: 'middle' })
						return (<div className="col-lg-4 col-xs-6" key={key}>
							<div className="feature-box">
								<span><img src={item.url} style={item.style} /></span><span style={item.textStyle}>{item.text}</span>
							</div>
						</div>)
					})}
				</div>
			</div>
		
			<div className="container-fluid round-circle">
				<NeuralDashboad />
			</div>
        </div>
    )
}
export default Roboticarm;