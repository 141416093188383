import React from "react";
import NeuralDashboad from "../product/neural.component";
import ReactPlayer from "react-player";
import cleaningRobotVideo from "../../Assets/slides/cleaningrobot.mp4";

const Cleaningrobot = () => {
  const featureBoxArr = [
    {
      url: require("../../Assets/features/cr_icon1.png"),
      text: "Spill Detection",
    },
    {
      url: require("../../Assets/features/cr_icon2.png"),
      text: "Wireless Charging Dock",
    },
    {
      url: require("../../Assets/features/cr_icon3.png"),
      text: "Authority Alerts",
    },
    {
      url: require("../../Assets/features/dricon1.png"),
      text: "Complete On-Chip Intelligence",
    },
    {
      url: require("../../Assets/features/dricon5.png"),
      text: "Daily Reports",
    },
    {
      url: require("../../Assets/features/cr_icon4.png"),
      text: "User-Friendly Dashboard",
    },
    {
      url: require("../../Assets/features/dricon6.png"),
      text: "Enhanced Security and Privacy",
    },
    {
      url: require("../../Assets/features/cr_icon5.png"),
      text: "Self Navigation System",
    },
    {
      url: require("../../Assets/features/cr_icon6.png"),
      text: "Cloud Platform",
    },
  ];
  return (
    <div className="accord-wrap">
      <div className="container-fluid accord">
        <div className="row">
          <div className="col-lg-7 col-md-push-7">
            <h3 className="mt50">Cleaning Robot</h3>
            <p>
              Cleaning robot is equipped with various sensors,
              <br />
              brushes, and suction mechanisms to effectively
              <br />
              remove dust, dirt, and debris from the surfaces.
            </p>
            <a href="/contact" className="btn action2">
              Enquire now
            </a>
          </div>

          <div className="col-lg-5 col-md-pull-5">
            <img src={require("../../Assets/slides/cr-slide1.png")} />
          </div>
        </div>
      </div>
      <div className="container-fluid acco-img">
        <div className="row" id="desktop">
          <div className="col-lg-4">
            <img
              src={require("../../Assets/slides/cr-slide2.png")}
              className="img-responsive"
            />
          </div>

          <div className="col-lg-4">
            <img
              src={require("../../Assets/slides/cr-slide3.png")}
              className="img-responsive"
            />
          </div>

          <div className="col-lg-4">
            <img
              src={require("../../Assets/slides/cr-slide4.png")}
              className="img-responsive"
            />
          </div>
        </div>

        <div id="mobile">
          <div
            id="carousel-example-generic2"
            className="carousel slide"
            data-ride="carousel"
          >
            <ol className="carousel-indicators">
              <li
                data-target="#carousel-example-generic2"
                data-slide-to="0"
                className="active"
              ></li>
              <li
                data-target="#carousel-example-generic2"
                data-slide-to="1"
              ></li>
              <li
                data-target="#carousel-example-generic2"
                data-slide-to="2"
              ></li>
            </ol>

            <div className="carousel-inner" role="listbox">
              <div className="item active">
                <img
                  src={require("../../Assets/slides/cr-slide2.png")}
                  className="img-responsive"
                />
              </div>

              <div className="item">
                <img
                  src={require("../../Assets/slides/cr-slide3.png")}
                  className="img-responsive"
                />
              </div>

              <div className="item">
                <img
                  src={require("../../Assets/slides/cr-slide4.png")}
                  className="img-responsive"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid acco-slide">
        <div
          id="carousel-example-generic"
          className="carousel slide"
          data-ride="carousel"
          data-interval="false"
        >
          <ol className="carousel-indicators">
            <li
              data-target="#carousel-example-generic"
              data-slide-to="0"
              className="active"
            ></li>
            <li data-target="#carousel-example-generic" data-slide-to="1"></li>
            {/* <li data-target="#carousel-example-generic" data-slide-to="2"></li> */}
          </ol>

          <div className="carousel-inner" role="listbox">
            <div className="item active">
              <div className="row">
                <div className="col-lg-6">
                  <img src={require("../../Assets/slides/cr-slide5.png")} />
                </div>

                <div className="col-lg-6">
                  <div className="carousel-caption">
                    <p>Huge waste collection capacity</p>
                    <h3>
                      The robot has a waste storage capacity of 20.5cm <br />
                      diameter.
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            <div className="item">
              <div className="row">
                <div className="col-lg-6">
                  <img src={require("../../Assets/slides/cr-slide5.png")} />
                </div>

                <div className="col-lg-6">
                  <div className="carousel-caption">
                    <p>Spill Detection</p>
                    <h3>
                      The robot automatically detects and clean the spills
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid features">
        <h4>Features</h4>
        <div className="row">
          {featureBoxArr.map((item, key) => {
            item.textStyle = Object.assign(
              item.textStyle ? item.textStyle : {},
              { width: "60%", display: "inline-block", verticalAlign: "middle" }
            );
            return (
              <div className="col-lg-4 col-xs-6" key={key}>
                <div className="feature-box">
                  <span>
                    <img src={item.url} style={item.style} />
                  </span>
                  <span style={item.textStyle}>{item.text}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="cr-product-video">
        <div className="video">
          <iframe
            width='100%'
            height='600px'
            // src="https://www.youtube.com/embed/qSc19Q5YXLs"
            src={cleaningRobotVideo}
         frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div className="container-fluid round-circle">
        <NeuralDashboad />
      </div>
    </div>
  );
};

export default Cleaningrobot;
