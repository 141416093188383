import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo1 from "../../Assets/logo1.png";
import $ from "jquery";
import "./nav.css";
const NavBar = () => {
  const [showBrocherCard, setShowBrocherCard] = useState(false);

  const toggleBrocherCard = () => {
    setShowBrocherCard(!showBrocherCard);
  };

  const downloadPDF = (fileURL) => {
    const link = document.createElement("a");
    link.href = fileURL;
    link.download = fileURL.substr(fileURL.lastIndexOf("/") + 1);
    link.target = "_blank";
    link.click();
  };

  const downloadAllPDFs = () => {
    brochures.forEach((brochure) => {
      downloadPDF(brochure.url);
    });
  };

  const brochures = [
    {
      name: "All Robots",
      image: require("../../Assets/brocher-download.png"),
      url: require("../../Assets/pdf/All-Robots.pdf"),
    },
    {
      name: "Plastering Robot",
      image: require("../../Assets/brocher-download.png"),
      url: require("../../Assets/pdf/Plastering-Robot.pdf"),
    },
    {
      name: "Cleaning Robot",
      image: require("../../Assets/brocher-download.png"),
      url: require("../../Assets/pdf/Cleaning-robot.pdf"),
    },
  ];

  const location = useLocation();
  let tabName =
    location.pathname.split("/")[location.pathname.split("/").length - 1];
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
      if ($(".in").length > 0) $("#navbar").removeClass("in");
    }, 100);
  }, [tabName]);
  return (
    <div className="container-fluid topnav">
      <nav className="navbar navbar-default">
        <div className="container-fluid">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#navbar"
              aria-expanded="false"
              aria-controls="navbar"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <a className="navbar-brand" href="/">
              <img src={require("../../Assets/logo1.png")} />
            </a>
          </div>
          <div id="navbar" className="navbar-collapse collapse">
            <ul className="nav navbar-nav navbar-right">
              <li>
                <Link className={tabName === "" ? "act" : ""} to={"/"}>
                  {" "}
                  Home
                </Link>
              </li>
              <li>
                <Link
                  className={tabName === "products" ? "act" : ""}
                  to={"/products"}
                >
                  {" "}
                  Products
                </Link>
              </li>
              <li>
                <Link
                  className={tabName === "innovative" ? "act" : ""}
                  to={"/innovative"}
                >
                  {" "}
                  Innovation
                </Link>
              </li>
              <li>
                <Link
                  className={tabName === "about" ? "act" : ""}
                  to={"/about"}
                >
                  {" "}
                  About
                </Link>
              </li>
              <Link className="btn action" to={"/contact"}>
                {" "}
                Contact
              </Link>
              <li>
                <div onClick={toggleBrocherCard}>
                  <span className={`btn action ${showBrocherCard ? "opacity" : ""}`}>Brochures</span>
                  <div className={`overlay ${showBrocherCard ? "show" : ""}`}></div>
                  <div className={`brochure ${showBrocherCard ? "show" : ""}`} style={{ display: showBrocherCard ? "block" : "none" }}>
                    <div className="brocher-card">
                      <div className="card-header">
                        <h2>Download brochures</h2>
                      </div>
                      <div className="card-content">
                        {brochures.map((brochure, index) => (
                          <div className="pdf" onClick={() => downloadPDF(brochure.url)} key={index}>
                            <p>{brochure.name}</p>
                            <img src={brochure.image} alt="Download" />
                          </div>
                        ))}
                      </div>
                      <div className="button-download">
                        <button className="download-button" onClick={downloadAllPDFs}>
                          Download all
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
