import { useEffect, useState } from 'react';
// import $ from 'jquery';
import './about.css';
// import AboutCarousel from './carousel.about.component';

import InfosysSvg from '../../Assets/about/infosys.svg';
import OzoneSvg from '../../Assets/about/ozone.svg';
import KilimoiotSvg from '../../Assets/about/Kilimoiot.svg';
import MouldNetworksSvg from '../../Assets/about/Mould_Networks.svg';
import GmrSvg from '../../Assets/about/GMR.svg';


import  SpaSvg  from '../../Assets/about/SPA.svg';
import  HBotsSvg  from '../../Assets/about/H_BOTS.svg';
import  WarwickSvg  from '../../Assets/about/Warwick.svg';
import  QueenMarySvg  from '../../Assets/about/Queen_Mary.svg';
import  VayuSvg  from '../../Assets/about/Vayu.svg';

// import './jquery.rotating-slider.js'
const AboutDashboard = () => {
    let temp = [{
        imgUrl1: require('../../Assets/ellipse.png'),
        imgUrl2: require('../../Assets/footer-logo.png'),
        text: 'Founded',
        aText: 'February 2021', width: '240px', style: { top: '130px' }, textStyle: {
            'minHeight': '100px', 'fontSize': '25px', 'letterSpacing': '1px',
            'fontWeight': '600'
        }
    },
    {
        imgUrl1: require('../../Assets/ellipse.png'),
        imgUrl2: require('../../Assets/slide2sm.png'),
        text: 'Delivered a prototype model for the Accord Robot.',
        aText: 'June 2021', width: '140px', style: { top: '10px' }
    },
    {
        imgUrl1: require('../../Assets/ellipse.png'),
        imgUrl2: require('../../Assets/slide1sm.png'),
        text: 'Finished prototype of the Healthcare Robot.',
        aText: 'October 2021', width: '120px', style: { top: '-20px' },
    },
    {
        imgUrl1: require('../../Assets/ellipse.png'),
        imgUrl2: require('../../Assets/inf.png'),
        text: 'Signed with Infosys as a Vendor for Robotic supplies.',
        aText: 'February 2022', width: '240px', style: { top: '130px' }, textStyle: {
            'minHeight': '130px'
        }
    },
    {
        imgUrl1: require('../../Assets/ellipse.png'),
        imgUrl2: require('../../Assets/card-icon.png'),
        text: 'Signed a contract for Al Software for a Surveillance Robot.',
        aText: 'April 2022', width: '140px', style: { top: '130px' }, textStyle: {
            'minHeight': '150px'
        }
    }];
    const [rotatingData, setRotatingData] = useState(temp.concat(temp));
    const [ourValuesData, setOurValuesData] = useState([
        {
            heading: 'Privacy',
            text: "We, at Neural Foundry, value each individual's privacy and ensure that it is strictly maintained at every step of our journey. As a result, we are one of the first companies to deploy \"On-Chip Intelligence\" in our products."
        }, {
            heading: 'Transparency',
            text: "Machine Learning techniques, often referred to as Black Boxes, can be easily misinterpreted as a complex algorithm behind the curtains of the products.We, at Neural Foundry, are proud to let our users know \"exactly\" what is going on to assure the fact that there is nothing that could harm their sensitive data."
        },
        {
            heading: 'Versatility', text: 'Artificial Intelligence is a powerful, yet broad, area of expertise that can be adapted and deployed in "any" field to help/ assist individuals in excelling in their job with an added intelligent, logically well - argued perspective'
        }]
    );
    const [dataList, setDataList] = useState({
        'Founders': [
            { imgUrl: require('../../Assets/dhruva.jpg'), text: 'Dhruva Konidena', text2: 'Founder & CEO' },
            { imgUrl: require('../../Assets/nikhil.jpg'), text: 'Nikhil Kalamannil Oommen', text2: 'Co- Founder, AI head' },
            { imgUrl: require('../../Assets/kisshhan.jpg'), text: 'Kisshhan PSV', text2: 'Co- Founder, Robotics head' },
            { imgUrl: require('../../Assets/ravi.jpg'), text: 'Ravi Konidena', text2: 'Co- Founder' },
        ],
        'Advisors': [
            // { imgUrl: require('../../Assets/suraj.jpg'), text: 'Suraj Ramaprasad', text2: 'Advisor' },
            { imgUrl: require('../../Assets/demi.jpg'), text: 'Keith McDowell', text2: 'Board Advisor' }
        ]
    });
    const [partnerList, setPartnerList] = useState([
        { url: InfosysSvg },
        { url: OzoneSvg },
        { url: KilimoiotSvg },
        { url: MouldNetworksSvg },
        // { url: GmrSvg },

    ])
    const [partnerList2, setPartnerList2] = useState([
        { url: GmrSvg },
        { url: SpaSvg },
        { url: HBotsSvg},
        { url: WarwickSvg },
        // { url: QueenMarySvg },
        // { url: VayuSvg },
    ])
    const [partnerList3, setPartnerList3] = useState([
        { url: QueenMarySvg },
        { url: VayuSvg },
        { url: require('../../Assets/patners/011.png') },
        { url: require('../../Assets/patners/012.png') },
        // { url: require('../../Assets/patners/013.png') },
        // { url: require('../../Assets/patners/014.png') },
        // { url: require('../../Assets/patners/015.png') },
    ])
    const [partnerList4, setPartnerList4] = useState([
        { url: require('../../Assets/patners/013.png') },
        { url: require('../../Assets/patners/014.png') },
        { url: require('../../Assets/patners/015.png') },
        { url: require('../../Assets/patners/016.png') },
    ]);

    useEffect(() => {
        // window.checkforslider();
    }, []);
    window.checkforslider();

    return (
        <div className='about-page'>
            <div className='container-fluid about-carousel'>
                {/* <AboutCarousel /> */}
            </div>
            <div className="container-fluid my-rotating">
                <div className="rotating-slider">
                    <ul className="slides">
                        {rotatingData.map((item, i) => {
                            return (<li key={i}>
                                <div className="inner">
                                    <img src={item.imgUrl1} className="img-responsive" />
                                    <div className="card" style={item.style}>
                                        {item.imgUrl2 ? <img width={item.width} src={item.imgUrl2} /> : ''}
                                        <p style={item.textStyle}>{item.text}</p>
                                        <a href="javascript:void(0);" style={{ cursor: 'default' }} className="btn card-btn">{item.aText}</a>
                                    </div>
                                </div>
                            </li>)
                        })}
                    </ul>
                </div>
            </div>

            <div className="container-fluid about-wrap">
                <h2>Neural Foundry is the synergy of
                    <br />
                    <span>AI and Robotics</span>
                </h2>
                <h3 className='mt-50'>Our Values</h3>
                <div className="values">
                    <div className="row">
                        {ourValuesData.map((item, i) => {
                            return <div className="col-lg-4 border-right" key={i}>
                                <h4>{item.heading}</h4>
                                <p style={{ color: '#B2E1EE' }}>{item.text}</p>
                            </div>
                        })}
                    </div>
                </div>

                {Object.keys(dataList).map((item, index) => {
                    return <div key={index}>
                        {item === 'Founders' ? <h5>{item}</h5> : <h6>{item}</h6>}
                        <div className="row" key={index + 100}>
                            {
                                dataList[item].map((internal, i) => {
                                    return <div className="col-lg-3" key={i}>
                                        <div className="fa-box">
                                            <img src={internal.imgUrl} className="img-responsive" />
                                            <p><strong>{internal.text}</strong></p>
                                            <p>{internal.text2}</p>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                })}
            </div>
            <div className="container-fluid partner">
                <h6>Partners</h6>
                {
                    <div className="row ten-columns hidden-mobile" >
                        {
                            partnerList.map((item, index) => {
                                return <div className="col-sm-2 col-xs-6" key={index}>
                                    <img src={item.url} />
                                </div>

                            })}
                    </div>
                }
                {
                    <div className="row ten-columns hidden-mobile" >
                        {
                            partnerList2.map((item, index) => {
                                return <div className="col-sm-2 col-xs-6" key={index}>
                                    <img src={item.url} />
                                </div>

                            })}
                    </div>
                }
                {
                    <div className="row ten-columns hidden-mobile" >
                        {
                            partnerList3.map((item, index) => {
                                return <div className="col-sm-2 col-xs-6" key={index}>
                                    <img src={item.url} />
                                </div>

                            })}
                    </div>
                }
                {
                    <div className="row ten-columns hidden-mobile" >
                        {
                            partnerList4.map((item, index) => {
                                return <div className="col-sm-2 col-xs-6" key={index}>
                                    <img src={item.url} />
                                </div>

                            })}
                    </div>
                }
                {
                    <div className="row ten-columns shown-mobile" >
                        {
                            partnerList.map((item, index) => {
                                return <div className="col-sm-2 col-xs-6" key={index}>
                                    <img src={item.url} />
                                </div>

                            })}
                        {
                            partnerList2.map((item, index) => {
                                return <div className="col-sm-2 col-xs-6" key={index}>
                                    <img src={item.url} />
                                </div>

                            })}
                        {
                            partnerList3.map((item, index) => {
                                return <div className="col-sm-2 col-xs-6" key={index}>
                                    <img src={item.url} />
                                </div>

                            })}
                    </div>
                }

            </div>

            <div className="container-fluid" style={{ marginTop: "50px" }}>
                <div className="container border">
                    <div className="row">
                        <div className="col-md-12" style={{ background: "#122230", marginTop: "50px", padding: "20px", display: "flex", alignItems: "center", color: "rgb(210 210 210)" }}>
                            <div className="col-md-4">
                                <img src={require("../../Assets/patners/007.png")} width="76px" style={{ float: "right" }} />
                            </div>
                            <div className="col-md-8">
                                <h3 className="text-white"><i>Our Robots Manufacturing Wing</i></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div >)
}
export default AboutDashboard