import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom";

const ProductSlides = (props) => {
    const [show, setShow] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    let tabName = location.pathname.split('/')[location.pathname.split('/').length - 1];
    const remClass = (opt) => {
        setShow(opt)
    }
    useEffect(() => {
        if (tabName === '') {
            remClass(false)
        } else {
            remClass(true)
        }
    }, [tabName])

    // list for home and products page
    const [list, setList] = useState(false);

    let product_list = [{
        label: 'Accord', url: require('../../Assets/home-pro1.jpg'), route: '/accord'
    }, {
        label: 'Healthcare', url: require('../../Assets/home-pro2.jpg'), route: '/healthcare'
    }, {
        label: 'Robotic Arm', url: require('../../Assets/home-pro3.jpg'), route: '/roboticarm'
    }];

    if(Object.keys(props).length){
        if(!list){
            setList((list) => product_list);
        }
    }
    else{
        let products = [{
            label: 'Delivery Robot', url: require('../../Assets/home-pro-4.jpg'), route: '/delivery-robot'
        }, {
            label: 'Plastering Robot', url: require('../../Assets/home-pro-5.jpg'), route: '/plastering-robot'
        }, 
        {
            label: 'Cleaning Robot', url: require('../../Assets/home-pro7.png'), route: '/cleaning-robot'
        },];

    let product_new = [
        {
            label: 'AR/VR', url: require('../../Assets/home-pro6.jpg'), route: '/ar-vr'
        },
       
      ];

        if(!list){
            let product_data = product_list.concat(products, product_new);
            setList((list) => product_data);
        }
    }

    //console.log(show)
    const goTo = (url) => {
        navigate(url);
    }

    return (
        <div className="row">
            {list && list.map((item, index) =>
                <div className="col-lg-4 mt-60" key={index}>
                    <div className="pro-box" onClick={e => goTo(item.route)} style={{ cursor: 'pointer' }}>
                        <img src={item.url} className="img-responsive" />
                        <div className="pro-hover">
                            <div className="bg-color shown-mobile">
                                <h3 style={{ bottom: '30px' }}>
                                    <Link to={item.route} style={{ textDecoration: 'none', color: '#fff' }}>{item.label}</Link>
                                </h3>
                            </div>
                            <div className="bg-color hidden-mobile">
                                {!show && <h3 className="pro-box-text hidden-mobile">
                                    <Link to={item.route} style={{ textDecoration: 'none', color: '#fff' }}>{item.label}</Link>
                                </h3>}
                            </div>
                        </div>
                    </div>
                    {show ? <h3 className="pro-box-text hidden-mobile">
                        <Link to={item.route} style={{ textDecoration: 'none', color: '#fff',fontSize: "3rem", display:"flex", justifyContent: "flex-start"}}>{item.label}</Link>
                    </h3> : ''}
                </div>)
            }
        </div>
        )
}
export default ProductSlides