import NeuralDashboad from "../product/neural.component"

const Arvr = () => {
    const featureBoxArr = [
        { url: require('../../Assets/features/aricon1.png'), text: 'Object Detection' },
        { url: require('../../Assets/features/aricon2.png'), text: 'Size Detection' },
        { url: require('../../Assets/features/aricon3.png'), text: 'Pattern Detection' },
        { url: require('../../Assets/feature4.png'), text: 'Conversational Abilities' },
        { url: require('../../Assets/icon_acces.png'), text: 'Additional Accessories' },
        { url: require('../../Assets/features/features2.png'), text: 'Voice Assistance' },
        { url: require('../../Assets/features/dricon6.png'), text: 'Enhanced Security and Privacy' },
        { url: require('../../Assets/features/plicon7.png'), text: 'Configured Mobile Device' },
        { url: require('../../Assets/feature5.png'), text: 'Daily Reports' },

    ];
    return (<div className="accord-wrap">
        <div className="container-fluid accord">
            <div className="row">

                <div className="col-lg-7 col-md-push-7">
                    <h3 className="mt50">AR / VR</h3>
                    <p>We are official software development partner of VUZIX India.<br/> We have build custom softwares tailoring to many applications.</p>
                    <a href="/contact" className="btn action2">Enquire now</a>
                </div>

                <div className="col-lg-5 col-md-pull-5">
                    <img src={require("../../Assets/slides/arslide1.png")} style={{ marginTop: "140px" }}  />
                </div>

            </div>
        </div>

        
        <div className="container-fluid acco-slide">
            <div id="carousel-example-generic" className="carousel slide" data-ride="carousel" data-interval="false">

                <ol className="carousel-indicators">
                    <li data-target="#carousel-example-generic" data-slide-to="0" className="active"></li>
                    <li data-target="#carousel-example-generic" data-slide-to="1"></li>
                </ol>


                <div className="carousel-inner" role="listbox">
                    <div className="item active">
					 <div className="row">
						
                            <div className="col-lg-6">
                                <img src={require("../../Assets/slides/arslide3.png")} />
                            </div>
                            <div className="col-lg-6">
                                <div className="carousel-caption text_desktop">
                                    
                        <p>Laboratory Enhancement with AR</p>
                        <h3>Using AR to enhance the quality control process in laboratories.</h3><br/><br/>
                                
                                </div>
                            </div>

                        </div>
						
                    </div>

                    <div className="item">
                        <div className="row">
                            <div className="col-lg-6">
                                <img src={require("../../Assets/slides/arslide2.png")} />
                            </div>

                            <div className="col-lg-6">
                                <div className="carousel-caption text_desktop">
                                    <p>Augmented Space for Testing Real-World objects</p>
                                    <h3>Assistive grips can help fallen individuals get back onto their feet.</h3>
                                
                                </div>
                            </div>
                        </div>
                    </div>
					
                </div>
            </div>
        </div>
        
        

        <div className="container-fluid features">
            <h4>Features</h4>
            <div className="row">
                {featureBoxArr.map((item, key) => {
                    item.textStyle = Object.assign(item.textStyle ? item.textStyle : {}, { width: '60%', display: 'inline-block', verticalAlign: 'middle' })
                    return (<div className="col-lg-4 col-xs-6" key={key}>
                        <div className="feature-box">
                            <span><img src={item.url} style={item.style} /></span><span style={item.textStyle}>{item.text}</span>
                        </div>
                    </div>)
                })}

            </div>
        </div>
        <div className="container-fluid round-circle">
            <NeuralDashboad />
        </div>
    </div>)
}
export default Arvr;