import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
    const [show, setShow] = useState(true);
    const location = useLocation();
    let tabName = location.pathname.split('/')[location.pathname.split('/').length - 1];
    const remClass = (opt) => {
        setShow(opt)
    }
    useEffect(() => {
        if (tabName === 'innovative' || tabName === 'accord' || tabName === 'delivery-robot' || tabName === 'plastering-robot' || tabName === 'ar-vr' || tabName === 'healthcare' || tabName==='roboticarm') {
            remClass(false)
        } else {
            remClass(true)
        }
    }, [tabName])

    let current_year = new Date().getFullYear();
    return (<>
        <div className="cl"></div>

        <div className={`container-fluid footer ${show ? 'mt-100' : ''}`}>
            {/* <div className="col-lg-12">
        <div id="carousel-example-generic" className="carousel slide" data-ride="carousel">
            <Slider slides={ImageSlider2Data} width={'100%'} autoPlayDelay={.2} slideDuration={.3}></Slider>
        </div>

    </div> */}
            <div className="row">
                <div className="col-lg-6 f-logo hidden-mobile">
                    <img src={require("../../Assets/footer-logo.png")} />
                </div>
                <div className="col-lg-6 f-logo shown-mobile" style={{ margin: '15px 0 40px 0   ' }}>
                    <img style={{ width: '60%' }} src={require("../../Assets/footer-logo.png")} />
                </div>
                <div className="col-lg-2 list text-center-desktop" style={{ margin: '0px 0 25px 0' }}>
                    <p><strong>Products</strong></p>
                    <ul>
                        <li><Link to={'/accord'}>Accord</Link></li>
                        <li><Link to={'/healthcare'}>Healthcare</Link></li>
                        <li><a href="/roboticarm">Robotic Arm</a></li>
                        <li><a href="/delivery-robot"> Delivery Robot </a></li>
                        <li><a href="/plastering-robot"> Plastering Robot </a></li>
                        <li><a href="/ar-vr"> AR/VR </a></li>
                        
                    </ul>
                </div>
                <div className="col-lg-2 list text-center-desktop" style={{ margin: '0px 0 25px 0' }}>
                    <p><strong>Innovation</strong></p>
                    <ul>
                        <li><Link to={'/casestudies'}>Case Studies</Link></li>
                    </ul>
                </div>


                <div className="col-lg-2 list text-center-desktop" style={{ margin: '0px 0 25px 0' }}>
                    <p><strong>Company</strong></p>
                    <ul>
                        <li><Link to={'/about'}>About</Link></li>
                        <li><Link to={'/contact'}>Contact</Link></li>
                        <li><a href="#">Careers</a></li>
                    </ul>
                </div>

            </div>
            {/*  <!--row--> */}
            <div className="row shown-mobile">
                <div className="col-md-12">

                    <hr />
                    <center>
                        <p>© 2022 Neural Foundry Ltd.</p>
                    </center>


                    <div className="inline-social">
                        <ul className="text-center" style={{ textAlign: 'center' }}>
                            <li>
                                <a href="https://www.linkedin.com/company/neural-foundry"><i className="fa fa-linkedin-square"></i></a>
                            </li>
                            <li><a href="https://twitter.com/neuralfoundryuk"><i className="fa fa-twitter-square"></i></a></li>
                            {/* <li><a href="#"><i className="fa fa-instagram"></i></a></li> */}
                            <li><a href="https://www.facebook.com/neuralfoundry/"><i className="fa fa-facebook-square"></i></a></li>
                            {/* <li><a href="#"><i className="fa fa-youtube-play"></i></a></li> */}
                        </ul>
                    </div>
                    {/* <!--inline-social--> */}
                    <hr />
                    <div className="col-lg-4">
                        <div className="inline-link">
                            <ul className="text-center">
                                <li><Link to={'/terms'}>Privacy Policy</Link></li>
                                <li><Link to={'/terms'}>Terms and Conditions</Link></li>
                            </ul>
                        </div>
                        {/* <!--inline-link--> */}
                    </div>


                </div>
            </div>
            <hr className="ftline hidden-mobile" />

            <div className="row hidden-mobile">
                <div className="col-lg-4">
                    <div className="inline-link">
                        <ul>
                            <li><Link to={'/terms'}>Privacy Policy</Link></li>
                            <li><Link to={'/terms'}>Terms and Conditions</Link></li>
                        </ul>
                    </div>
                    {/* <!--inline-link--> */}
                </div>
                {/* <!--col4--> */}

                <div className="col-lg-4">
                    <center>
                        <p>© {current_year} Neural Foundry Ltd.</p>
                    </center>
                </div>
                {/* <!--col4--> */}

                <div className="col-lg-4">
                    <div className="inline-social">
                        <ul>
                            <li><a href="https://www.linkedin.com/company/neural-foundry" target="_blank"><i className="fa fa-linkedin-square"></i></a></li>
                            <li><a href="https://twitter.com/neuralfoundryuk" target="_blank"><i className="fa fa-twitter-square"></i></a></li>
                            {/* <li><a href="#" target="_blank"><i className="fa fa-instagram"></i></a></li> */}
                            <li><a href="https://www.facebook.com/neuralfoundry/" target="_blank"><i className="fa fa-facebook-square"></i></a></li>
                            {/* <li><a href="#" target="_blank"><i className="fa fa-youtube-play"></i></a></li> */}
                        </ul>
                    </div>
                    {/* <!--inline-social--> */}
                </div>
                {/*   <!--col4--> */}
            </div>
            {/*  <!--row--> */}
        </div>
        {/* footer */}
        <div className="cl"></div>
        <div className="top-scroll" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}><i className="fa fa-angle-up"></i></div>
    </>)
}

export default Footer;