const NotFoundPage = () => {
    return (
        <div className="container-fluid wrap-404">
            <div className="row">
                <div className="col-lg-6">
                    <img src={require("../../Assets/404.png")} className="img-responsive" alt=""/>
                    <h2>Something went wrong</h2>
                </div>
            </div>
        </div>
    )
}

export default NotFoundPage;