import { useState } from "react";
import { Link } from "react-router-dom";
import Carousel from "../home/carousel.component";
import NeuralDashboad from "../product/neural.component";

const Inovative = () => {
    const [domainList1, setDomainList1] = useState([
        { name: 'Health Care', image: require('../../Assets/domain1.png') },
        { name: 'Agritech', image: require('../../Assets/domain2.png') },
        { name: 'Warehouse', image: require('../../Assets/domain3.png') }
    ]);
    const [domainList2, setDomainList2] = useState([
        { name: 'Automobile', image: require('../../Assets/domain4.png') },
        { name: 'Smart City', image: require('../../Assets/domain5.png') },
        { name: 'Industries', image: require('../../Assets/domain6.png') }
    ])
    return (
        <div className="innovative-wrap">
            <div className="container-fluid innovative">
                <div className="row">
                    <div className="col-lg-8 mt100">
                        <h1>We can turn your<br /><span>idea into reality</span></h1>
                    </div>
                    <div className="col-lg-4">
                        {/* <img src="images/dice.png" /> */}
                        <video width="100%" autoPlay muted loop playsInline>
                            <source src={require("../../Assets/hexagon.mp4")} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>

            </div>

            <div className="container-fluid inno-video">
                <h2>Our product development procedure</h2>
                <video width="100%" autoPlay muted loop playsInline>
                    <source src={require("../../Assets/our-development-procedure.mp4")} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            <div className="container-fluid domain hidden-mobile">
                <h3>Domains</h3>
                <div className="row">
                    {domainList1.map((item, i) => {
                        return <div key={i}><div className="col-lg-1" >
                            <img src={item.image} />
                        </div>
                            <div className="col-lg-3">
                                <p>{item.name}</p>
                            </div></div>
                    })}
                </div>
                <div className="row mt-50">
                    {domainList2.map((item, i) => {
                        return (
                            <div key={i} >
                                <div className="col-lg-1" >
                                    <img src={item.image} />
                                </div>
                                <div className="col-lg-3">
                                    <p>{item.name}</p>
                                </div></div>
                        )
                    })}
                </div>
            </div>
            <div className="container-fluid domain shown-mobile">
                <h3>Domains</h3>
                <div className="row">
                    {domainList1.map((item, i) => {
                        return <div key={i} className="col-xs-6 mb-20"><div className="col-lg-1" >
                            <img src={item.image} />
                        </div>
                            <div className="col-lg-3">
                                <p>{item.name}</p>
                            </div></div>
                    })}
                    {domainList2.map((item, i) => {
                        return (
                            <div key={i} className="col-xs-6 mb-20"><div className="col-lg-1" >
                                <img src={item.image} />
                            </div>
                                <div className="col-lg-3">
                                    <p>{item.name}</p>
                                </div></div>
                        )
                    })}
                </div>
            </div>
            <div className="container-fluid case hidden-mobile">
                <div className="case-in">
                    <h4>Case studies</h4>
                    <Link to={'/casestudies'} className="btn action3 pull-right m-l-btn mt-15">View All</Link>
                    <Carousel></Carousel>
                </div>
            </div>

            <div className="container-fluid case shown-mobile">
                <div className="case-in">
                    <h4>Case Studies</h4>
                    {/* <Link to={'/casestudies'} className="btn action3 pull-right m-l-btn mt-15">View All</Link> */}
                    <Carousel></Carousel>
                    <Link to={'/casestudies'} className="btn action3" style={{ margin: '30px 0 0 0px' }}>View All</Link>
                    <div className="clearfix"></div>
                </div>
            </div>
            <div className="container-fluid round-circle">
                <NeuralDashboad />
            </div>
        </div>
    )
}

export default Inovative;