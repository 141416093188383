import NeuralDashboad from "../product/neural.component";
import { useEffect, useState } from "react";

const Plasteringrobot = () => {
    const featureBoxArr = [
        { url: require('../../Assets/features/plicon8.png'), text: 'Depth Sensing' },
        { url: require('../../Assets/features/features2.png'), text: 'Voice Assistance' },
        { url: require('../../Assets/features/plicon2.png'), text: 'Wireless Charging Dock' },
        { url: require('../../Assets/features/dricon1.png'), text: 'Complete On-Chip Intelligence' },
        { url: require('../../Assets/features/dricon6.png'), text: 'Enhanced Security and Privacy' },
        { url: require('../../Assets/features/plicon7.png'), text: 'Configured Mobile Device' },
        { url: require('../../Assets/features/plicon9.png'), text: 'Terrain Awareness' },
        { url: require('../../Assets/features/plicon10.png'), text: 'In-Depth Wall Mapping' },
        { url: require('../../Assets/features/plicon11.png'), text: 'Self Guided System' },

    ];
    return (<div className="accord-wrap">
        <div className="container-fluid accord">
            <div className="row">

                <div className="col-lg-7 col-md-push-7">
                    <h3 className="mt50">Plastering Robot</h3>
                    <p>Autonomous plastering and depth sensing capabilities embedded on an easily portable and maneuverable machine which uses AI insights to perform tasks with precision.</p>
                    <a href="/contact" className="btn action2">Enquire now</a>
                </div>

                <div className="col-lg-5 col-md-pull-5">
                    <img src={require("../../Assets/slides/prslide_1.png")} />
                </div>

            </div>
        </div>
        <div className="container-fluid acco-img">
            <div className="row" id="desktop">
                <div className="col-lg-4">
                    <img src={require("../../Assets/slides/pr_slide1.png")} className="img-responsive" />
                </div>

                <div className="col-lg-4">
                    <img src={require("../../Assets/slides/pr_slide2.png")} className="img-responsive" />
                </div>

                <div className="col-lg-4">
                    <img src={require("../../Assets//slides/pr_slide3.png")} className="img-responsive" />
                </div>
            </div>

            <div id="mobile">
                <div id="carousel-example-generic2" className="carousel slide" data-ride="carousel">

                    <ol className="carousel-indicators">
                        <li data-target="#carousel-example-generic2" data-slide-to="0" className="active"></li>
                        <li data-target="#carousel-example-generic2" data-slide-to="1"></li>
                        <li data-target="#carousel-example-generic2" data-slide-to="2"></li>
                    </ol>

                    <div className="carousel-inner" role="listbox">
                        <div className="item active">
                            <img src={require("../../Assets/slides/prslide_2.png")} className="img-responsive" />
                        </div>

                        <div className="item">
                            <img src={require("../../Assets/slides/pr_slide2.png")} className="img-responsive" />
                        </div>

                        <div className="item">
                            <img src={require("../../Assets/slides/pr_slide3.png")} className="img-responsive" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container-fluid acco-slide">
            <div id="carousel-example-generic" className="carousel slide" data-ride="carousel" data-interval="false">

                <ol className="carousel-indicators">
                    <li data-target="#carousel-example-generic" data-slide-to="0" className="active"></li>
                    <li data-target="#carousel-example-generic" data-slide-to="1"></li>
                    <li data-target="#carousel-example-generic" data-slide-to="2"></li>
                </ol>


                <div className="carousel-inner" role="listbox">
                    <div className="item active">
                        <div className="row">
                            <div className="col-lg-6">
                                <img src={require("../../Assets/slides/pr_slide4.png")} />
                            </div>

                            <div className="col-lg-6">
                                <div className="carousel-caption">
                                    <p>Autonomous Maneuverability</p>
                                    <h3>Moves autonomously from one place to another while mapping the field of operation.</h3>
                                </div>
                            </div>
                        </div>
                    </div>

					 <div className="item">
                        <div className="row">
                            <div className="col-lg-6">
                                <img src={require("../../Assets/slides/pr_slide4.png")} />
                            </div>

                            <div className="col-lg-6">
                                <div className="carousel-caption">
                                    <p>Wall Inspection</p>
                                    <h3>The wall is inspected, and a depth sensing report is generated. After user approval, the robot begins plastering.</h3>
                                </div>
                            </div>
                        </div>
                    </div>
					
					 <div className="item">
                        <div className="row">
                            <div className="col-lg-6">
                                <img src={require("../../Assets/slides/pr_slide4.png")} />
                            </div>

                            <div className="col-lg-6">
                                <div className="carousel-caption">
                                    <p>AI Driven Plastering with Thickness Evaluation</p>
                                    <h3>The robot uses depth sensing and AI inputs to automatically adjust the thickness of the plastering materials at every location of the wall.</h3>
                                </div>
                            </div>
                        </div>
                    </div>
					
                </div>
            </div>
        </div>
        
        <div className="container-fluid features">
            <h4>Features</h4>
            <div className="row">
                {featureBoxArr.map((item, key) => {
                    item.textStyle = Object.assign(item.textStyle ? item.textStyle : {}, { width: '60%', display: 'inline-block', verticalAlign: 'middle' })
                    return (<div className="col-lg-4 col-xs-6" key={key}>
                        <div className="feature-box">
                            <span><img src={item.url} style={item.style} /></span><span style={item.textStyle}>{item.text}</span>
                        </div>
                    </div>)
                })}

            </div>
        </div>
        <div className="container-fluid round-circle">
            <NeuralDashboad />
        </div>
    </div>)
}
export default Plasteringrobot;