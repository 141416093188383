import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
    const baseURL = `${window.location.origin.toString()}/`;
    const { register, handleSubmit, reset, formState: { errors }, clearErrors } = useForm();
    const notify = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message);
    //console.log(baseURL);
    async function postData(url = '', data = {}) {
        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        });
        return response.json();
    }
    const saveForm = (data) => {
        // console.log(data);
        postData(baseURL + 'save.php', {
            name: data.name,
            email: data.email,
            message: data.message
        }).then((response) => {
            setSaved(true);
            notify(response.msg)
        }).catch((err) => {
            setSaved(true);
            notifyError(err.msg)
        })
    }
    const [isSuccess, setIsSuccess] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [saved, setSaved] = useState(false);
    const resetForm = () => {
        reset({
            name: "",
            email: "",
            message: "",
        })
        clearErrors();
    }
    useEffect(() => {
        resetForm()
    }, [saved]);
    const errorMessage = error => {
        return <div className="invalid-feedback">{error}</div>;
    };
    // Messages
    const required = "This field is required";
    const maxLength = "Your input exceed maximum length";
    const pattern = "Please input valid string";


    return (<div className="container-fluid contact-wrap">
        <h2>Contact Us</h2>
        <div className="row mt-60">
            <ToastContainer />
            <div className="col-lg-6">
                {/* <img src="../../Assets/map.png" className="img-responsive gmap" /> */}
                <div className="mapouter">
                    <div className="gmap_canvas">
                       <img src={require("../../Assets/map.png")} width="100%" />
                        </div>
						 </div>
            </div>
            <div className="col-lg-6">
                <div className="form pull-right">
                    <form onSubmit={handleSubmit(saveForm)}>
                        <div className="form-group">
                            <label>Name:</label>
                            <input className="form-control" type="text" placeholder="Name" {...register("name", { required: true, maxLength: 200 })} />
                            {errors.name && errors.name.type === "required" && errorMessage(required)}
                            {errors.name && errors.name.type === "maxLength" && errorMessage(maxLength)}
                            {/* <input
                                type="text" {...register("name", { required: true, maxLength: 200 })}
                                name="name"
                                //value={name}
                                // onChange={e => setName(e.target.value)}
                                className="form-control" /> */}
                        </div>


                        <div className="form-group">
                            <label>Email:</label>
                            <input className="form-control" type="text" placeholder="Email" {...register("email", { required: true, maxLength: 200, pattern: /^\S+@\S+$/i })} />
                            {errors.email && errors.email.type === "required" && errorMessage(required)}
                            {errors.email && errors.email.type === "maxLength" && errorMessage(maxLength)}
                            {errors.pattern && errors.pattern.type === "maxLength" && errorMessage(pattern)}
                            {/* <input
                                type="email"
                                //value={email}
                                {...register("email", { required: true, maxLength: 200, pattern: /^\S+@\S+$/i})}
                                //onChange={e => setEmail(e.target.value)}
                                name="email"
                                className="form-control" /> */}
                        </div>


                        <div className="form-group">
                            <label>Message:</label>
                            <textarea
                                name="message"
                                id="textarea"

                                //value={message}
                                {...register("message", { required: true, maxLength: 2000 })}

                            //onChange={e => setMessage(e.target.value)}
                            >

                            </textarea>
                            {errors.message && errors.message.type === "required" && errorMessage(required)}
                            {errors.message && errors.message.type === "maxLength" && errorMessage(maxLength)}
                        </div>


                        <div className="form-group">
                            <button type="submit" className="btn action3 pull-right"><span>
                                <img src={require("../../Assets/send.png")} /></span> Send</button>
                        </div>

                    </form>
                </div>

            </div>

        </div>


        <div className="contact-details">
            <div className="row">
                <div className="col-lg-1">
                    <img src={require("../../Assets/mail.png")} />
                </div>

                <div className="col-lg-3">
                    <p>info@neuralfoundry.co.uk</p>
                </div>


                <div className="col-lg-1">
                    <img src={require("../../Assets/phone.png")} />
                </div>

                <div className="col-lg-3">
                    <p>(+44) 020 8622 3011</p>
                </div>


                <div className="col-lg-1">
                    <img src={require("../../Assets/pin.png")} />
                </div>

                <div className="col-lg-3">
                    <p>6-9 The Square, Hayes, Uxbridge UB11 1FW, United Kingdom</p>
                </div>

            </div>

        </div>


        <div className="row">

            <div className="col-lg-4">
                <div className="contact-details" style={{ marginTop: "40px" }}>
                    <div className="row">
                        <div className="col-lg-3">
                            <img src={require("../../Assets/india.png")} style={{ marginTop: "4px" }} />
                        </div>

                        <div className="col-lg-9">
                            <p>NF H-Bots Robotics Private Limited <br />SF2, Empire Square, Jawahar Nagar,<br /> Jubilee Hills, Hyderabad, India - 500033 </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-4">
                <div className="contact-details" style={{ marginTop: "40px" }}>
                    <div className="row">
                        <div className="col-lg-3">
                            <img src={require("../../Assets/germani.png")} style={{ marginTop: "4px" }} />
                        </div>

                        <div className="col-lg-9">
                            <p>Neural Foundry GmbH <br /> Konstanzer Str. 15 10707 Berlin</p>
                            <br />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-4">
                <div className="contact-details" style={{ marginTop: "40px" }}>
                    <div className="row">
                        <div className="col-lg-3">
                            <img src={require("../../Assets/united-arab-emirates.png")} style={{ marginTop: "4px" }} />
                        </div>

                        <div className="col-lg-9">
                            <p>Neural Foundry Dubai,<br />Flat No 512, 3317 B St - Mashrabia Bld, <br/>Al Mankhool , Dubai UAE</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div className="row mt-100">
            <h3 className="text-center contact-text1">"Where some see uncertainty,</h3>
            <h3 className="text-center contact-text2"> we see possibility"</h3>
        </div>
    </div>
    )
}

export default Contact;