const Terms = () => {
    return (
        

        <div className="container-fluid contact-wrap terms">
        <h2>Terms & Conditions</h2>
        <p>Neural Foundry values its users’ privacy. This Privacy Policy (“Policy”) will help you understand how we collect and use personal information from those who visit our website or make use of our online facilities and services, and what we will and will not do with the information we collect. Our Policy has been designed and created to ensure those affiliated with Neural Foundry of our commitment and realization of our obligation not only to meet but to exceed most existing privacy standards.</p>
        </div>
    
    ) 
}

export default Terms;