import React from "react";
import "./banner.css";

const Banner = () => {
    const navigateToURL = () => {
      window.open("https://urp.neuralfoundry.co.uk", "_blank");
    };
  return (
    <div onClick={navigateToURL}>
      <div className="banner">
        <span className="banner-content">
        United Robotics Platform - Click to know more
        </span>
        <img src={require("../../Assets/banner-image.png")} alt="Banner" />
      </div>
    </div>
  );
};

export default Banner;
