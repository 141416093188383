import SimpleImageSlider from "react-simple-image-slider";

const Slider = ({ slides, width, autoPlayDelay, slideDuration, height }) => {
    width = width || 363;
    autoPlayDelay = autoPlayDelay || 2;
    slideDuration = slideDuration || .5;
    height = height || 549;
    //console.log(slides)
    return (
        <div className="carousel-inner">
            <SimpleImageSlider
                width={width}
                height={height}
                images={slides}
                showBullets={false}
                showNavs={false}
                autoPlay={true}
                autoPlayDelay={autoPlayDelay}
                loop={true}
                slideDuration={slideDuration}
                useGPURender={true}
                startIndex={0}
            />
        </div>
    );
};

export default Slider;