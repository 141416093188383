import NeuralDashboad from "../product/neural.component";

const Deliveryrobot = () => {
    const featureBoxArr = [
        { url: require('../../Assets/features/dricon1.png'), text: 'Complete On-Chip Intelligence' },
        { url: require('../../Assets/icon_acces.png'), text: 'Additional Accessories' },
        { url: require('../../Assets/feature3.png'), text: 'Authority Alerts' },
        { url: require('../../Assets/feature4.png'), text: 'Voice Interaction' },
        { url: require('../../Assets/feature5.png'), text: 'Daily Reports' },
        { url: require('../../Assets/feature6.png'), text: 'User-Friendly Dashboard' },
        { url: require('../../Assets/features/dricon6.png'), text: 'Enhanced Security and Privacy' },
        { url: require('../../Assets/feature8.png'), text: 'Self Navigation System' },
        { url: require('../../Assets/feature9.png'), text: 'Cloud Platform' },

    ];
    return (<div className="accord-wrap">
        <div className="container-fluid accord">
            <div className="row">

                <div className="col-lg-7 col-md-push-7">
                    <h3 className="mt50">Delivery Robot</h3>
                    <p>
					Delivery robot is designed to handle last<br/> mile deliveries in India. It is capable of managing<br/> multiple deliveries simultaneously.
					</p>
                    <a href="/contact" className="btn action2">Enquire now</a>
                </div>

                <div className="col-lg-5 col-md-pull-5">
                    <img src={require("../../Assets/slides/drslide5.png")} />
                </div>

            </div>
        </div>
        <div className="container-fluid acco-img">
            <div className="row" id="desktop">
                <div className="col-lg-4">
                    <img src={require("../../Assets/slides/dr_slide1.png")} className="img-responsive" />
                </div>

                <div className="col-lg-4">
                    <img src={require("../../Assets/slides/dr_slide2.png")} className="img-responsive" />
                </div>

                <div className="col-lg-4">
                    <img src={require("../../Assets/slides/dr_slide3.png")} className="img-responsive" />
                </div>
            </div>

            <div id="mobile">
                <div id="carousel-example-generic2" className="carousel slide" data-ride="carousel">

                    <ol className="carousel-indicators">
                        <li data-target="#carousel-example-generic2" data-slide-to="0" className="active"></li>
                        <li data-target="#carousel-example-generic2" data-slide-to="1"></li>
                        <li data-target="#carousel-example-generic2" data-slide-to="2"></li>
                    </ol>

                    <div className="carousel-inner" role="listbox">
                        <div className="item active">
                            <img src={require("../../Assets/slides/dr_slide1.png")} className="img-responsive" />
                        </div>

                        <div className="item">
                            <img src={require("../../Assets/slides/dr_slide2.png")} className="img-responsive" />
                        </div>

                        <div className="item">
                            <img src={require("../../Assets/slides/dr_slide3.png")} className="img-responsive" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container-fluid acco-slide">
            <div id="carousel-example-generic" className="carousel slide" data-ride="carousel" data-interval="false">

                <ol className="carousel-indicators">
                    <li data-target="#carousel-example-generic" data-slide-to="0" className="active"></li>
                    <li data-target="#carousel-example-generic" data-slide-to="1"></li>
                    <li data-target="#carousel-example-generic" data-slide-to="2"></li>
                </ol>


                <div className="carousel-inner" role="listbox">
                    <div className="item active">
                        <div className="row">
                            <div className="col-lg-6">
                                <img src={require("../../Assets/slides/dr_slide4.png")} />
                            </div>

                            <div className="col-lg-6">
                                <div className="carousel-caption">
                                    <p>Huge Storage Capacity</p>
                                    <h3>The robot has a storage capacity of 280L. <br/>It can store upto 20 packages simultaneously.</h3>
                                </div>
                            </div>
                        </div>
                    </div>
					
					 <div className="item">
                        <div className="row">
                            <div className="col-lg-6">
                                <img src={require("../../Assets/slides/dr_slide1.png")} />
                            </div>

                            <div className="col-lg-6">
                                <div className="carousel-caption">
                                    <p>Safe and Secure</p>
                                    <h3>The robot has advance theft protection system.</h3>
                                </div>
                            </div>
                        </div>
                    </div>

					<div className="item">
                        <div className="row">
                            <div className="col-lg-6">
                                <img src={require("../../Assets/slides/dr_slide3.png")} />
                            </div>

                            <div className="col-lg-6">
                                <div className="carousel-caption">
                                    <p>Self Navigation System</p>
                                    <h3>Delivery robot comes with a LiDAR based self-navigation system which helps the robot move seamlessly.</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="container-fluid features">
            <h4>Features</h4>
            <div className="row">
                {featureBoxArr.map((item, key) => {
                    item.textStyle = Object.assign(item.textStyle ? item.textStyle : {}, { width: '60%', display: 'inline-block', verticalAlign: 'middle' })
                    return (<div className="col-lg-4 col-xs-6" key={key}>
                        <div className="feature-box">
                            <span><img src={item.url} style={item.style} /></span><span style={item.textStyle}>{item.text}</span>
                        </div>
                    </div>)
                })}

            </div>
        </div>
        <div className="container-fluid round-circle">
            <NeuralDashboad />
        </div>
    </div>)
}
export default Deliveryrobot;