import './App.css';
import './media.css';
import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomeDashboard from './components/home/home.component';
import NavBar from './components/Nav/nav.component';
import Product from './components/product/product.compnent';
import AboutDashboard from './components/about/about.component';
import Footer from './components/footer/footer.component';
import Contact from './components/contact/contact.component';
import NotFoundPage from './components/404page/404.component';
import Inovative from './components/inovative/inovative.component';
import CaseStudies from './components/casestudies/casestudies.component';
import Accord from './components/accord/accord.component';
import HealthCare from './components/healthcare/healthcare.component';
import DeliveryRobot from './components/deliveryrobot/deliveryrobot.component';
import PlasteringRobot from './components/plasteringrobot/plasteringrobot.component';
import Cleaningrobot from './components/cleaningrobot/cleaningrobot.component';
import Arvr from './components/arvr/arvr.component';
import RoboticArm from './components/roboticarm/roboticarm.component';
import Terms from './components/terms/terms.component';
import Banner from './components/banner/banner.component';
import Delivery from './components/deliveryrobot/delivery.component';

function App() {
  const [showBrocherCard, setShowBrocherCard] = useState(false);
  return (
    <BrowserRouter>
      
      {/* <NavBar></NavBar> */}
      <Routes>
        <Route path="/" index  element={
            <>
              {/* <Banner />
              <NavBar />
              <HomeDashboard /> */}
                <Banner
                showBrocherCard={showBrocherCard}
                setShowBrocherCard={setShowBrocherCard}
              />
              <NavBar showBrocherCard={showBrocherCard} />
              <HomeDashboard
                className={showBrocherCard ? 'opacity' : ''}
              />
            </>
          }></Route>
          <Route 
                path="/*"
                element={
                  <>
                    <NavBar />
                    <Routes>
                      <Route path="/products" element={<Product />} />
                      <Route path="/about" element={<AboutDashboard />} />
                      <Route path="/contact" element={<Contact />} />
                      <Route path="/innovative" element={<Inovative />} />
                      <Route path="/casestudies" element={<CaseStudies />} />
                      <Route path="/accord" element={<Accord />} />
                      <Route path="/healthcare" element={<HealthCare />} />
                      <Route path="/delivery-robot" element={<DeliveryRobot />} />
                      <Route path="/delivery" element={<Delivery />} />
                      <Route path="/plastering-robot" element={<PlasteringRobot />} />
                      <Route path="/roboticarm" element={<RoboticArm />} />
                      <Route path="/ar-vr" element={<Arvr />} />
                      <Route path="/cleaning-robot" element={<Cleaningrobot />} />
                      <Route path="/terms" element={<Terms />} />
                      <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                  </>
                }
              /> 
         {/* <NavBar></NavBar> 
        <Route path="/products" element={<Product />}></Route>
        <Route path="/about" element={<AboutDashboard />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/innovative" element={<Inovative />}></Route>
        <Route path="/casestudies" element={<CaseStudies />}></Route>
        <Route path="/accord" element={<Accord />}></Route>
        <Route path="/healthcare" element={<HealthCare />}></Route>
        <Route path="/delivery-robot" element={<DeliveryRobot />}></Route>
        <Route path="/plastering-robot" element={<PlasteringRobot />}></Route>
        <Route path="/roboticarm" element={<RoboticArm />}></Route>
        <Route path="/ar-vr" element={<Arvr />}></Route>
        <Route path="/cleaning-robot" element={<Cleaningrobot />}></Route>
        <Route path="/terms" element={<Terms />}></Route>
        <Route path="/brochures" element={<Brocher />}></Route>
        <Route path="*" element={<NotFoundPage />}></Route> */}
      </Routes>
      <Footer></Footer>
    </BrowserRouter>
  );
}

export default App;
