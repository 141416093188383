import NeuralDashboad from "../product/neural.component"

const Accord = () => {
    const featureBoxArr = [
        { url: require('../../Assets/feature1.png'), text: 'Temperature Screening' },
        { url: require('../../Assets/feature2.png'), text: 'UV Disinfection' },
        { url: require('../../Assets/feature3.png'), text: 'Authority Alerts' },
        { url: require('../../Assets/feature4.png'), text: 'Voice Interaction' },
        { url: require('../../Assets/feature5.png'), text: 'Daily Reports' },
        { url: require('../../Assets/feature6.png'), text: 'User-Friendly Dashboard' },
        { url: require('../../Assets/feature7.png'), text: 'Facial Recognition' },
        { url: require('../../Assets/feature8.png'), text: 'Self Navigation System' },
        { url: require('../../Assets/feature9.png'), text: 'Cloud Platform' },

    ];
    
    return (<div className="accord-wrap">
        <div className="container-fluid accord">
            <div className="row">

                <div className="col-lg-7 col-md-push-7">
                    <h3 className="mt50">Accord</h3>
                    <p>Our Accord Robot can aid in cleaning as it can be used as a part of the regular sterilisation cycle.</p>
                    <a href="/contact" className="btn action2">Enquire now</a>
                </div>

                <div className="col-lg-5 col-md-pull-5">
                    <img src={require("../../Assets/slide2.png")} />
                </div>

            </div>
        </div>
        <div className="container-fluid acco-img">
            <div className="row" id="desktop">
                <div className="col-lg-4">
                    <img src={require("../../Assets/acco1.png")} className="img-responsive" />
                </div>

                <div className="col-lg-4">
                    <img src={require("../../Assets/acco2.png")} className="img-responsive" />
                </div>

                <div className="col-lg-4">
                    <img src={require("../../Assets/acco3.png")} className="img-responsive" />
                </div>
            </div>

            <div id="mobile">
                <div id="carousel-example-generic2" className="carousel slide" data-ride="carousel">

                    <ol className="carousel-indicators">
                        <li data-target="#carousel-example-generic2" data-slide-to="0" className="active"></li>
                        <li data-target="#carousel-example-generic2" data-slide-to="1"></li>
                        <li data-target="#carousel-example-generic2" data-slide-to="2"></li>
                    </ol>

                    <div className="carousel-inner" role="listbox">
                        <div className="item active">
                            <img src={require("../../Assets/acco1.png")} className="img-responsive" />
                        </div>

                        <div className="item">
                            <img src={require("../../Assets/acco2.png")} className="img-responsive" />
                        </div>

                        <div className="item">
                            <img src={require("../../Assets/acco3.png")} className="img-responsive" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container-fluid acco-slide">
            <div id="carousel-example-generic" className="carousel slide" data-ride="carousel" data-interval="false">

                <ol className="carousel-indicators">
                    <li data-target="#carousel-example-generic" data-slide-to="0" className="active"></li>
                    <li data-target="#carousel-example-generic" data-slide-to="1"></li>
                    <li data-target="#carousel-example-generic" data-slide-to="2"></li>
                </ol>


                <div className="carousel-inner" role="listbox">
                    <div className="item active">
                        <div className="row">
                            <div className="col-lg-6">
                                <video width="100%" autoPlay muted loop playsInline>
                                    <source src={require("../../Assets/Accord1.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>

                            <div className="col-lg-6">
                                <div className="carousel-caption">
                                    <p>UV Disinfection</p>
                                    <h3>Prevent and reduce the spread of infectious diseases, virus, bacteria and other harmful microorganisms by breaking down their DNA structure.</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="item">
                        <div className="row">
                            <div className="col-lg-6">
                                <video width="100%" autoPlay muted loop playsInline>
                                    <source src={require("../../Assets/Accord2.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>

                            <div className="col-lg-6">
                                <div className="carousel-caption">
                                    <p>Temperature Screening</p>
                                    <h3>To prevent the spread of infectious viruses and diseases, Accord performs primary screenings and alerts the authorities.</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="item">
                        <div className="row">
                            <div className="col-lg-6">
                                <video width="100%" autoPlay muted loop playsInline>
                                    <source src={require("../../Assets/Accord3.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>

                            <div className="col-lg-6">
                                <div className="carousel-caption">
                                    <p>Self Navigation System</p>
                                    <h3>Accord comes with a LiDAR-based self-navigation system, enabling the robot move seamlessly across office floors.</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid workflow">
            <h3>Disinfection System Workflow</h3>

            <div className="arrow-position">
                <div className="round-arrow">
                    <img src={require("../../Assets/round-arrow.png")} className="img-responsive" />
                </div>

                <div className="row row-position">
                    <div className="col-lg-4">
                        <div className="arrow-box">
                            <center><img src={require("../../Assets/arrow-icon1.png")} /></center>
                            <p>Staff orders ACCORD Robot via the app</p>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="arrow-box">
                            <center><img src={require("../../Assets/arrow-icon2.png")} /></center>
                            <p>The ACCORD Robot takes the elevator and opens the door</p>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="arrow-box">
                            <center><img src={require("../../Assets/arrow-icon3.png")} /></center>
                            <p>The ACCORD Robot enters the room for cleaning</p>
                        </div>
                    </div>


                    <div className="col-lg-4 mt-25 shown-mobile">
                        <div className="arrow-box">
                            <center><img src={require("../../Assets/arrow-icon6.png")} /></center>
                            <p>The ACCORD Robot disinfects the room</p>
                        </div>
                    </div>
                    <div className="col-lg-4 mt-25  hidden-mobile">
                        <div className="arrow-box">
                            <center><img src={require("../../Assets/arrow-icon4.png")} /></center>
                            <p>The report is generated, and the robot returns</p>
                        </div>
                    </div>

                    <div className="col-lg-4 mt-25">
                        <div className="arrow-box">
                            <center><img src={require("../../Assets/arrow-icon5.png")} /></center>
                            <p>The ACCORD Robot sends a notification upon disinfection</p>
                        </div>
                    </div>

                    <div className="col-lg-4 mt-25 hidden-mobile">
                        <div className="arrow-box">
                            <center><img src={require("../../Assets/arrow-icon6.png")} /></center>
                            <p>The ACCORD Robot disinfects the room</p>
                        </div>
                    </div>
                    <div className="col-lg-4 mt-25 shown-mobile">
                        <div className="arrow-box">
                            <center><img src={require("../../Assets/arrow-icon4.png")} /></center>
                            <p>The report is made and the robot returns</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid features">
            <h4>Features</h4>
            <div className="row">
                {featureBoxArr.map((item, key) => {
                    item.textStyle = Object.assign(item.textStyle ? item.textStyle : {}, { width: '60%', display: 'inline-block', verticalAlign: 'middle' })
                    return (<div className="col-lg-4 col-xs-6" key={key}>
                        <div className="feature-box">
                            <span><img src={item.url} style={item.style} /></span><span style={item.textStyle}>{item.text}</span>
                        </div>
                    </div>)
                })}

            </div>
        </div>
        <div className="container-fluid round-circle">
            <NeuralDashboad />
        </div>
    </div>)
}
export default Accord;