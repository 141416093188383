import { useState } from "react";


const CaseStudies = () => {
    const [list, setList] = useState([{
        label: 'Accord', url: require('../../Assets/caro1.jpg'), route: '/accord'
    }, {
        label: 'Healthcare', url: require('../../Assets/caro2.jpg'), route: '/healthcare'
    }, {
        label: 'Healthcare', url: require('../../Assets/caro3.jpg'), route: '/healthcare'
    }, {
        label: 'Robotic Arm', url: require('../../Assets/caro4.jpg'), route: '/accord'
    }]);
    return (<div className="container-fluid products-wrap">
        <h2>Case Studies</h2>
        <div className="row mt-60">
            {list.map((item, index) =>
                <div className="col-lg-4 mb-30" key={index}>
                    <div className="">
                        <img src={item.url} className="img-responsive border-radius20" />
                        {/* <div className="pro-hover">
                            <h3><Link to={item.route} style={{ textDecoration: 'none', color: '#fff' }}>{item.label}</Link></h3>
                        </div> */}
                    </div>
                </div>)
            }
        </div>
    </div>)
}
export default CaseStudies;