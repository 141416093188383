import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
const NeuralDashboad = () => {
    const [cName, setCName] = useState('');
    const [changeName, setChangeName] = useState(true);
    const MINUTE_MS = 5000;

    const changeCName = () => {
        setCName(cName === "nueral-logo" ? 'nueral-logo-anti' : 'nueral-logo')
    }
    const changeLocalName = () => {
        setChangeName(!changeName)
    }

    useEffect(() => {
        changeCName();
        const interval = setInterval(() => {
            changeLocalName()
        }, MINUTE_MS);
        return () => clearInterval(interval);
    }, [changeName])
    return (
        <div className="c-img nueral">
            <div className='nueral-header'>
                <img src={require("../../Assets/round-circle.png")} id="r-img" className={cName} alt="logo" />
                <center className="get-in-touch">
                    <Link to={'/contact'} className='btn action'>Get in touch </Link>
                </center>
            </div>
        </div>
    );
}
export default NeuralDashboad;