import NeuralDashboad from "../product/neural.component";
const HealthCare = () => {
    const featureBoxArr = [
        { url: require('../../Assets/heal-1.png'), text: 'Fall Detection and Support' },
        { url: require('../../Assets/heal-2.png'), text: 'Voice Assistance' },
        { url: require('../../Assets/heal-3.png'), text: 'Wireless Charging Dock' },
        { url: require('../../Assets/heal-4.png'), text: 'Ergonomically Placed Assistive Grips', imgStyle: { verticalAlign: 'top' } },
        { url: require('../../Assets/heal-5.png'), text: 'Medication Reminders' },
        { url: require('../../Assets/icon_acces.png'), text: 'Additional Accessories' },
        { url: require('../../Assets/heal-7.png'), text: 'Conversational Abilities' },
        { url: require('../../Assets/heal-8.png'), text: 'Intuitive Display with Integrated App' },
        { url: require('../../Assets/heal-9.png'), text: 'Teleconsultation Services' },
        { url: require('../../Assets/heal-10.png'), text: 'Complete On-Chip Intelligence' },
        { url: require('../../Assets/heal-11.png'), text: 'Enhanced Security and Privacy' },
        { url: require('../../Assets/heal-12.png'), text: 'Configured Mobile Device' },
    ];
    return (
        <div>
            <div className="container-fluid accord">
                <div className="row">

                    <div className="col-lg-7 col-md-push-7">
                        <h3 className="mt50">Healthcare</h3>
                        <p>The robot is designed to take care of the elderly. <br></br>
It helps them to overcome loneliness, detects if they fall, and assists them in getting back up.</p>
                        <a href="/contact" className="btn action2">Enquire now</a>
                    </div>

                    <div className="col-lg-5 col-md-pull-5">
                        <img src={require("../../Assets/slide1.png")} />
                    </div>
                </div>
            </div>
            <div className="container-fluid acco-img">
                <div className="row" id="desktop">
                    <div className="col-lg-4">
                        <img src={require("../../Assets/health1.png")} className="img-responsive" />
                    </div>

                    <div className="col-lg-4">
                        <img src={require("../../Assets/health2.png")} className="img-responsive" />
                    </div>

                    <div className="col-lg-4">
                        <img src={require("../../Assets/health3.png")} className="img-responsive" />
                    </div>
                </div>

                <div id="mobile">
                    <div id="carousel-example-generic2" className="carousel slide" data-ride="carousel">

                        <ol className="carousel-indicators">
                            <li data-target="#carousel-example-generic2" data-slide-to="0" className="active"></li>
                            <li data-target="#carousel-example-generic2" data-slide-to="1"></li>
                            <li data-target="#carousel-example-generic2" data-slide-to="2"></li>
                        </ol>

                        <div className="carousel-inner" role="listbox">
                            <div className="item active">
                                <img src={require("../../Assets/health1.png")} className="img-responsive" />
                            </div>

                            <div className="item">
                                <img src={require("../../Assets/health2.png")} className="img-responsive" />
                            </div>

                            <div className="item">
                                <img src={require("../../Assets/health3.png")} className="img-responsive" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid acco-slide">
                <div id="carousel-example-generic" className="carousel slide" data-ride="carousel" data-interval="false">

                    <ol className="carousel-indicators">
                        <li data-target="#carousel-example-generic" data-slide-to="0" className="active"></li>
                        <li data-target="#carousel-example-generic" data-slide-to="1"></li>
                        <li data-target="#carousel-example-generic" data-slide-to="2"></li>
                    </ol>


                    <div className="carousel-inner" role="listbox">
                        <div className="item active">
                            <div className="row">
                                <div className="col-lg-6">
                                    <video width="100%" autoPlay muted loop playsInline>
                                        <source src={require("../../Assets/Healthcare1.mp4")} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>

                                <div className="col-lg-6">
                                    <div className="carousel-caption">
                                        <p>Fall Detection and Support</p>
                                        <h3>Detects whether a person has fallen with the AI-powered camera and navigates towards the person to act as a supportive agent.</h3>
                                    </div>
                                </div>The system uses an AI-powered camera to detect if a person has fallen. Upon detection, the robot navigates towards the individual to provide supportive assistance. 
                            </div>
                        </div>

                        <div className="item">
                            <div className="row">
                                <div className="col-lg-6">
                                    <video width="100%" autoPlay muted loop playsInline>
                                        <source src={require("../../Assets/Healthcare2.mp4")} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>

                                <div className="col-lg-6">
                                    <div className="carousel-caption">
                                        <p>Fall detection and support</p>
                                        <h3>Detects whether a person has fallen with the AI-powered camera and navigates towards the person to act as a supportive agent.</h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="item">
                            <div className="row">
                                <div className="col-lg-6">
                                    <video width="100%" autoPlay muted loop playsInline>
                                        <source src={require("../../Assets/Healthcare3.mp4")} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>

                                <div className="col-lg-6">
                                    <div className="carousel-caption">
                                        <p>Fall detection and support</p>
                                        <h3>Detects whether a person has fallen with the AI-powered camera and navigates towards the person to act as a supportive agent.</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid features">
                <h4>Features</h4>
                <div className="row">
                    {featureBoxArr.map((item, key) => {
                        item.textStyle = Object.assign(item.textStyle ? item.textStyle : {}, { width: '60%', display: 'inline-block', verticalAlign: 'middle' })
                        return (<div className="col-lg-4 col-xs-6" key={key}>
                            <div className="feature-box">
                                <span><img src={item.url} style={item.style} /></span><span style={item.textStyle}>{item.text}</span>
                            </div>
                        </div>)
                    })}
                </div>
            </div>
            
        <div className="container-fluid round-circle">
            <NeuralDashboad />
        </div>
        </div>
        
    )
}
export default HealthCare;