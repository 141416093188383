import React from 'react'
import './home.css';
import Slider from './slider.component'
import Carousel from './carousel.component'
import ProductSlides from '../product/productslides.component'
import { Link } from 'react-router-dom';
import Contact from '../contact/contact.component';
// import Banner from "./../../components/banner/banner.component"

const HomeDashboard = () => {
    const ImageSlider1Data = [{ url: require('../../Assets/slide1.png') }, { url: require('../../Assets/slide2.png') }];
    const ImageSlider2Data = [{
        label: 'Accord', url: require('../../Assets/home-pro1.jpg')
    }, {
        label: 'Healthcare', url: require('../../Assets/home-pro2.jpg')
    }, {
        label: 'Robotic Arm', url: require('../../Assets/home-pro3.jpg')
    }];
    return (
        <div className="col-xxl-12 home-page">
            {/* <div className="home-banner">
                <Banner></Banner>
            </div> */}
            <div className="container-fluid header">
                <div className="row">
                    <div className="col-lg-8 mt100">
                        <h1>Creating next<br />generation of <span>AI Robotics</span></h1>
                        <p className='we-combine'>We strategically combine AI and robotics to develop <br className='hidden-mobile' />solutions to the most complex problems.</p>
                        <Link to={'/contact'} className="btn action2">Get in touch</Link>
                    </div>
                    <div className="col-lg-4">
                        <div id="carousel-example-generic" className="carousel slide" data-ride="carousel">
                            <Slider slides={ImageSlider1Data}></Slider>
                        </div>
                    </div>
                    {/* <!--col4--> */}
                </div>
                {/* {/*  <!--row--> */}
            </div>
            {/* <!--header--> */}

            <div className="container-fluid home-pro mt-100 hidden-mobile">
                <div className="home-pro-in">
                    <h2>Our products</h2>
                    <Link to={'/products'} className="btn action3 pull-right m-l-btn mt-15">View All</Link>
                    <ProductSlides is_home="1"/>

                    {/*  <!--row--> */}
                </div>
                {/* <!--home-pro-in--> */}
            </div>
            {/* <!--home-pro--> */}
            <div className="container-fluid home-pro mt-100 shown-mobile">
                <div className="home-pro-in">
                    <h2>Our products</h2>
                    <div id="carousel-example-generic" className="carousel slide " data-ride="carousel">
                        <Slider slides={ImageSlider2Data} height={369} width={329}></Slider>
                    </div>
                    <Link to={'/products'} className="btn action3 pull-left">View All</Link>
                    <div className="clearfix"></div>
                </div>
                {/* <!--home-pro-in--> */}
            </div>
            {/* <!--home-pro--> */}

            <div className="container-fluid ino-solution">
                <div className="row">


                    <div className="col-lg-7 col-md-push-7">
                        <h3 className="mt50">Innovative Solutioning</h3>
                        <p>We can develop custom hardware and <br className='hidden-mobile' />software solutions tailored to your needs.</p>
                        <a href="#" ></a>
                        <Link className="btn action2" to={'/innovative'}>Know more</Link>
                    </div>
                    {/* <!--col7--> */}
                    <div className="col-lg-5 col-md-pull-5">
                        <img src={require("../../Assets/gaadi.png")} />
                    </div>
                    {/* <!--col5--> */}
                </div>
                {/*  <!--row--> */}
            </div>
            {/* <!--ino-solution--> */}

            <div className="container-fluid case hidden-mobile">
                <div className="case-in">
                    <h4>Case studies</h4>
                    <Link to={'/casestudies'} className="btn action3 pull-right m-l-btn mt-15">View All</Link>
                    <Carousel></Carousel>
                </div>
                {/* <!--case-in--> */}
            </div>
            <div className="container-fluid case shown-mobile">
                <div className="case-in">
                    <h4>Case studies</h4>
                    <Carousel></Carousel>
                    <Link to={'/casestudies'} className="btn action3" style={{ margin: '30px 0 0 0px' }}>View All</Link>
                    <div className="clearfix"></div>
                </div>
                {/* <!--case-in--> */}
            </div>
            {/* <!--case--> */}
        </div >
    )
}

export default HomeDashboard